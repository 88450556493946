import React from 'react';

export const ConnectSection = () => {
  return (
    <section className="connect-section" id="Connect">
      <div>
        <h2 className="section-heading">Connect</h2>
      </div>
      <ul>
        <li>
          <span>LinkedIn:</span>
          @<a href="https://linkedin.com/in/joseph-w-tatum" target="_blank" rel="noopener noreferrer">joseph-w-tatum</a>
        </li>
        <li>
          <span>Github:</span>
          @<a href="https://github.com/josephtatum" target="_blank" rel="noopener noreferrer">josephtatum</a>
        </li>
        <li>
          <span>Twitter:</span>
          @<a href="https://twitter.com/josephwtatum" target="_blank" rel="noopener noreferrer">josephwtatum</a>
        </li>
        <li>
          <span>Email:</span>
          <a href="mailto: josephtatum@gmail.com">josephtatum@gmail.com</a>
        </li>
      </ul>
    </section>
  );
};
