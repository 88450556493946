import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import github from "../../assets/github.svg";
import twitter from "../../assets/twitter.svg";

export const NavLinks = () => {
  const sections = ["About", "Connect"];

  const pageList = sections.map((section) => {
    return (
      <li className="anchor-link" key={section}>
        <NavLink smooth to={`#${section}`}>
          {section}
        </NavLink>
      </li>
    );
  });

  return (
    <>
      {pageList}
      <li className="social-img">
        <a
          href="http://www.github.com/josephtatum"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={github} />
        </a>
      </li>
      <li className="social-img">
        <a
          href="http://www.twitter.com/josephwtatum"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitter} />
        </a>
      </li>
    </>
  );
};
