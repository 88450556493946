import React from 'react';
import { useAlbums } from '../hooks/useAlbums';
import placeholderArtwork from '../assets/album-artwork.png';
export const SpinningSection = () => {

  const { albums } = useAlbums();

  let animtaionDelay = 0;
  let listItem;
  
  return (
    <section className="spinning-section">
      <div>
        <h2 className="section-heading">Spinning</h2>
      </div>
      <ul id="albums">
        {albums.map((item, i) => {
          listItem = <li key={i} data-aos="fade-up" data-aos-delay={animtaionDelay} data-aos-easing="ease-in-sine" data-aos-anchor="#albums">
            <a target="_blank" rel="noopener noreferrer" href={`https://open.spotify.com/search/${encodeURI(item.name)}%20${encodeURI(item.artist.name)}`}>
              {item.image[3]['#text'] ? 
                <img src={item.image[3]['#text']} alt={item.name} />
                :
                <img src={placeholderArtwork} alt={item.name} />
              }
            </a>
          </li>;
          animtaionDelay = animtaionDelay + 100;
          return listItem;
        })}
      </ul>
    </section>
  );
};
