import React from 'react';
import { NavLinks } from './NavLinks';
import logo from '../../assets/jt-mark.svg';

export const Nav = () => {
  return (
    <nav>
      <div className="nav-container">
        <img src={logo} />
        <ul>
          <NavLinks />
        </ul>
      </div>
    </nav>
  );
};
