import { useState, useEffect } from 'react';
import { getAlbums } from '../services/getAlbums';
export const useAlbums = () => {

  const [albums, setAlbums] = useState([]); 

  useEffect(() => {
    getAlbums()
      .then(res => {
        setAlbums(res.topalbums.album);
      });
  }, []);

  return { albums };

};
