import React from "react";
import { AboutPortraits } from "./AboutPortraits";

export const AboutSection = () => {
  return (
    <section className="about-section" id="About">
      <div>
        <h2 className="section-heading">About</h2>
      </div>
      <div className="about-right-side-container">
        <AboutPortraits />
        <p style={{ marginBottom: "45px" }}>
          I’m a software developer with a lot of excitment for the front end.
          With 6 years of combined professional experience as a designer and now
          developer, building apps that are well written and nice to look is
          where my happy place is. In my spare time, you'll likely find me
          taking on a project at my house or maybe eating an almond croissant
          with a sleeping cat in my lap.
        </p>

        <p>
          Currently I spend my work time as a Software Engineer at{" "}
          <a href="https://newrelic.com/" target="_blank" rel="noreferrer">
            New Relic
          </a>
          .
        </p>
      </div>
    </section>
  );
};
