import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { Hero } from "../containers/Hero";
import "../styles/styles.css";
import "../favicon.png";
import AOS from "aos";
import { AboutSection } from "./About";
import { ProjectsSection } from "./projects/Projects";
import { ConnectSection } from "./Connect";
import { SpinningSection } from "./Spinning";
import { Footer } from "./Footer";

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const props = useSpring({
    config: { duration: 675 },
    opacity: 1,
    from: { opacity: 0 },
    delay: 350,
  });

  return (
    <Router>
      <animated.div style={props}>
        <Hero />
        <section className="second-section-container">
          <section className="content-container">
            <AboutSection />
            {/* <ProjectsSection /> */}
            <ConnectSection />
            <SpinningSection />
          </section>
        </section>
        <Footer />
      </animated.div>
    </Router>
  );
}
