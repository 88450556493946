import React from 'react';
import { Nav } from '../components/nav/Nav';
import '../styles/styles.css';

export const Hero = () => {
  return (
    <>
      <Nav />
      <section className="hero-section">
        <div className='hero-text'>
          <h1>Hi. I&apos;m <span>Joseph Tatum</span>, a Front End Developer & Designer living in Portland, OR.</h1>
        </div>
      </section>
    </>
  );
};
