import React from 'react';
import JosephOne from '../assets/joseph-shake-it.mp4';
import JosephTwo from '../assets/joseph-norway.mp4';
import JosephThree from '../assets/joseph-plants.mp4';

export const AboutPortraits = () => {
  return (
    <div className="video-portraits" id="videoPortraits">
      <div className="video-container-1" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-anchor="#videoPortraits">
        <video src={JosephOne} className="about-video-main" autoPlay loop muted playsInline></video>
        <span>Cheesing on California 1</span>
      </div>
      <div className="video-container-2" data-aos="fade-up" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-anchor="#videoPortraits">
        <video src={JosephTwo} className="about-video-left" autoPlay loop muted playsInline></video>
        <span>A windy day in Bergen, Norway</span>
      </div>
      <div className="video-container-3" data-aos="fade-up" data-aos-delay="150" data-aos-easing="ease-in-sine" data-aos-anchor="#videoPortraits">
        <video src={JosephThree} className="about-video-right" autoPlay loop muted playsInline></video>
        <span>Trying not to kill the garden</span>
      </div>
    </div>
  );
};
